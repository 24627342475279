import dayjs from 'dayjs';

export const formatDateStart = (dateStart, dateEnd) => {
  const dateYear = Number(dayjs(dateStart).format('YYYY'));
  const dateEndYear = Number(dayjs(dateEnd).format('YYYY'));

  const start = date => {
    if (date !== dateEndYear) {
      return dayjs(dateStart).format('DD MMMM YYYY');
    }
    return dayjs(dateStart).format('DD MMMM');
  };

  return start(dateYear);
};

export const formatDateEnd = (dateEnd, isCruise) => {
  const thisYear = Number(dayjs().format('YYYY'));
  const dateYear = Number(dayjs(dateEnd).format('YYYY'));

  const start = date => {
    if (date !== thisYear) {
      if (isCruise) {
        return `${dayjs(dateEnd).format('DD MMMM YYYY')} года`;
      }
      return dayjs(dateEnd).format('DD MMMM YYYY');
    }
    return dayjs(dateEnd).format('DD MMMM');
  };

  return start(dateYear);
};
